var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.interception.length > 0,
        expression: "interception.length>0",
      },
    ],
    attrs: {
      "row-key": "id",
      columns: _vm.columns,
      "data-source": _vm.interception,
      scroll: { x: 1000, y: 360 },
      pagination: {
        pageSize: _vm.pager.pageSize,
        total: _vm.total,
        showTotal: function (total) {
          return "共 " + total + " 条数据"
        },
        showQuickJumper: true,
        showSizeChanger: true,
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }