<template>
  <a-table
    v-show="interception.length>0"
    row-key="id"
    :columns="columns"
    :data-source="interception"
    :scroll="{ x: 1000, y: 360 }"
    :pagination="{
      pageSize: pager.pageSize,
      total: total,
      showTotal: (total) => `共 ${total} 条数据`,
      showQuickJumper: true,
      showSizeChanger: true
    }"
  >
  <!--  -->
  </a-table>
</template>

<script>
export default {
  name: 'Extable',
  props: {
  },
  data() {
    return {
      pager: {
        pageSize: 10
      },
      total: 0,
      columns: [],
      interception: []
    }
  },
  mounted() {
    // window.onresize = () => {
    //   return (() => {
    //     debugger
    //   })()
    // }
  },
  methods: {
    initData(dataList) {
      this.interception = []
      const { errorList } = dataList
      if (this.dataType(errorList) !== 'Null' && errorList.length > 0) {
        this.handleDataList(errorList)
      } else {
        this.interception = dataList.dataList
        this.handleDataList(this.interception)
      }
      this.total = this.interception.length
    },
    initColumns(dataList) {
      this.columns = []
      if (dataList.headColumn !== '') {
        dataList.headColumn.forEach((item, index) => {
          const obj = {
            title: item.title,
            dataIndex: item.field,
            key: item.field,
            align: 'center',
            width: '180px'
          }
          const widthArr = ['idType', 'staffStatus', 'entryDate']
          if (widthArr.includes(obj.key)) {
            obj.width = '120px'
          }
          this.columns.push(obj)
        })
        const detailObj = {
          title: '异常详情',
          dataIndex: 'details',
          key: 'details',
          fixed: 'right',
          width: '400px'
        }
        const lineNum = {
          title: '行号',
          dataIndex: 'lineNum',
          key: 'lineNum',
          fixed: 'left',
          width: '50px'
        }
        if (dataList.errorList.length !== 0) {
          this.columns.push(detailObj)
          this.columns.splice(0, 0, lineNum)
        }
      }
    },
    handleDataList(arr) {
      arr.forEach((item, index) => {
        item.data.details = item.details
        item.data.lineNum = item.lineNum
        item.data.id = index
        item = item.data
        this.interception.splice(index, 1, item)
      })
    },
    dataType(data) {
      return Object.prototype.toString.call(data).split(' ')[1].slice(0, -1)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-table-row-cell-break-word { white-space: normal !important; }
</style>
