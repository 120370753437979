var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "upload-file" }, [
        _c(
          "div",
          { staticClass: "top_model" },
          [
            _vm._m(0),
            _c(
              "a-button",
              {
                attrs: { antprefix: "download" },
                on: { click: _vm.downLoadFile },
              },
              [_vm._v(" 下载模板 ")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "upload_main" }, [
          _c("p", { staticClass: "upload_main_p" }, [
            _vm._v("2.上传准备好的表格内容"),
          ]),
          _c(
            "div",
            { attrs: { id: "upLoad" } },
            [
              _c(
                "a-upload-dragger",
                {
                  attrs: {
                    name: "file",
                    multiple: _vm.multiple,
                    accept: ".xlsx",
                    "list-type": "picture",
                    "show-upload-list": _vm.showUpdateList,
                    "file-list": _vm.fileList,
                    "before-upload": _vm.beforeUpload,
                    "custom-request": _vm.customRequest,
                    disabled: _vm.uploadContentStatus === "ing",
                  },
                  on: { change: _vm.handleChange },
                },
                [
                  _c("div", { staticClass: "container" }, [
                    _vm.uploadContentStatus === "before"
                      ? _c("div", [
                          _c("p", { staticClass: "ant-upload-drag-icon" }, [
                            _c("img", {
                              staticClass: "xlsx_img",
                              attrs: { src: _vm.grayXlsx, alt: "" },
                            }),
                          ]),
                          _c("p", { staticClass: "ant-upload-text" }, [
                            _vm._v("将文件拖拽到此处，或 "),
                            _c("a", [_vm._v("点击上传")]),
                          ]),
                          _c("p", { staticClass: "ant-upload-hint" }, [
                            _vm._v("只能上传xlsx文件格式，且不超过20M"),
                          ]),
                        ])
                      : _vm.uploadContentStatus === "dragover"
                      ? _c("div", [_c("p", [_vm._v("松开鼠标上传文件")])])
                      : _vm.uploadContentStatus === "ok"
                      ? _c("div", [
                          _c("p", { staticClass: "ant-upload-drag-icon" }, [
                            _c("img", {
                              staticClass: "xlsx_img",
                              attrs: { src: _vm.xlsx, alt: "" },
                            }),
                          ]),
                          _c("p", { staticClass: "ant-upload-text" }, [
                            _vm._v(_vm._s(_vm.fileInfo.name)),
                          ]),
                          _c("a", { staticClass: "ant-upload-hin" }, [
                            _vm._v("重新上传"),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "title" },
        [_c("Extable", { ref: "extable" })],
        1
      ),
      _c("AttendanceShift", {
        ref: "attendanceShift",
        on: { change: _vm.downloadShift },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("1.下载标准模板"),
      _c("span", [_vm._v("根据提示信息完善表格内容")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }