<template>
  <div>
    <div class="upload-file">
      <div class="top_model">
        <p>1.下载标准模板<span>根据提示信息完善表格内容</span></p>
        <a-button antprefix="download" @click="downLoadFile">
          下载模板
        </a-button>
      </div>
      <div class="upload_main">
        <p class="upload_main_p">2.上传准备好的表格内容</p>
        <div id="upLoad">
          <a-upload-dragger
            name="file"
            :multiple="multiple"
            accept=".xlsx"
            list-type="picture"
            :show-upload-list="showUpdateList"
            :file-list="fileList"
            :before-upload="beforeUpload"
            :custom-request="customRequest"
            :disabled="uploadContentStatus === 'ing'"
            @change="handleChange"
          >
            <div class="container">
              <div v-if="uploadContentStatus === 'before'">
                <p class="ant-upload-drag-icon">
                  <img class="xlsx_img" :src="grayXlsx" alt="">
                </p>
                <p class="ant-upload-text">将文件拖拽到此处，或 <a>点击上传</a></p>
                <p class="ant-upload-hint">只能上传xlsx文件格式，且不超过20M</p>
              </div>
              <!-- <div v-else-if="uploadContentStatus === 'ing'" class="loading">
              <a-spin>
                <a-icon
                  slot="indicator"
                  type="loading"
                  style="font-size: 24px"
                  spin
                />
              </a-spin>
              <div v-if="schedule < 100">正在上传{{ schedule }}%</div>
              <div v-else>
                正在解析...
              </div>
            </div>
                <a-icon slot="indicator" type="loading" style="font-size: 80px" spin />
              </a-spin>
            </div> -->
              <div v-else-if="uploadContentStatus === 'dragover'">
                <p>松开鼠标上传文件</p>
              </div>
              <div v-else-if="uploadContentStatus === 'ok'">
                <p class="ant-upload-drag-icon">
                  <img class="xlsx_img" :src="xlsx" alt="">
                </p>
                <p class="ant-upload-text">{{ fileInfo.name }}</p>
                <a class="ant-upload-hin">重新上传</a>
              </div>
            </div>

          </a-upload-dragger>
        </div>

      </div>

    </div>
    <div class="title">
      <Extable ref="extable" />
    </div>
    <!-- <div class="confirm_btn">
      <a-button :disabled="!uploadSuccess" type="primary" @click="showModel">确认上传</a-button>
      <a-button v-else class="error_btn" type="primary" @click="errorClick">确认上传</a-button>
    </div> -->
    <!-- 校验表格 用ref隔代调用方法并传参 参数为接口返回的数组-->
    <!-- this.$refs.extable.initData(this.dataList)
        this.$refs.extable.initColumns(this.dataList) -->
    <!-- 考勤-排班表模板下载选择月份 -->
    <AttendanceShift ref="attendanceShift" @change="downloadShift" />
  </div>
</template>

<script>
import moment from 'moment'
import Extable from './exceptionTable.vue'
import { downLoadTemplate, batchImportStaff, checkBatchImportStaff } from '@/services/user/roster.js'
import {
  batchImportChangeApi,
  batchImportExchangeApi,
  batchImportGrantVacationAdjustment,
  batchImportDeductVacationAdjustment,
  batchImportShift,
  getImportTemplate,
  batchImportClock,
  batchImportApplyAppendPunch,
  batchImportApplyVacation,
  batchImportApplyOutside,
  batchImportApplyTravel,
  batchImportApplyOvertime } from '@/services/attendance/config.js'
import { batchImportOrg, checkBatchImportOrg } from '@/services/organization/framework.js'
import { batchSetFixedSalary, updateStaffFixedSalary, exportBatchSetTemplate, exportBatchUpdateTemplate } from '@/services/wage/wageArchives.js'
export default {
  name: 'BatchImport',
  components: {
    Extable,
    AttendanceShift: () => import('./attendanceShift.vue')
  },
  props: {
    // 下载模板的模板id
    modelId: {
      type: String,
      default: '1526856910525984769'
    },
    // 是否支持多文件上传
    multiple: {
      type: Boolean,
      default: false
    },
    showUpdateList: {
      type: Boolean,
      default: true
    },
    // 文件列表
    fileList: {
      type: Array,
      default: () => []
    },
    // 限制文件大小  默认最大20MB
    fileSize: {
      type: Number,
      default: 20
    },
    // 考勤模块
    matchField: {
      type: String,
      default: 'staffName'
    },
    importTemplate: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dataType: {
        sta: 'info',
        message: '请先上传符合规则的文件'
      },
      dataList: [],
      fileInfo: {},
      // comFileLIst: this.fileList,
      xlsx: require('@/assets/img/xlsx.png'),
      grayXlsx: require('@/assets/img/upload/grayXlsx.png'),
      // 上传组件中心位置显示
      uploadContentStatus: 'before',
      uploadSuccess: false,
      schedule: 0,
      oldUploadContentStatus: 'before' // 记录上一步状态
    }
  },
  watch: {
    uploadContentStatus(value, oldval) {
      const arr = ['before', 'ok']
      if (arr.indexOf(value) < arr.indexOf(oldval)) {
        this.oldUploadContentStatus = oldval
      } else if (oldval === 'ing') {
        this.oldUploadContentStatus = 'before'
      } else {
        return
      }
    }
  },
  mounted() {
    this.coordinate()
  },
  methods: {
    coordinate() {
      addEventListener('dragover', this.dragoverfun)
      addEventListener('drop', this.dragendfun)
    },
    clickBack() {
      removeEventListener('dragover', this.dragoverfun)
      removeEventListener('drop', this.dragendfun)
    },
    dragoverfun(event) {
      const UPLOADHEIGHT = 172
      const box = document.getElementById('upLoad')
      const dom = box.getBoundingClientRect()
      const leftTop = [dom.x, dom.y]
      const rightBottom = [dom.x + dom.width, dom.y + UPLOADHEIGHT]
      if (leftTop[1] <= event.pageY && rightBottom[1] >= event.pageY && leftTop[0] <= event.pageX && rightBottom[0] >= event.pageX) {
        this.uploadContentStatus = 'dragover'
      } else {
        this.uploadContentStatus = this.oldUploadContentStatus
      }
    },
    dragendfun() {
      this.uploadContentStatus = this.oldUploadContentStatus
    },
    // 下载文件模板
    downLoadFile() {
      const { matchField, importTemplate } = this
      if (importTemplate === 'shift') {
        this.$refs.attendanceShift.visible = true
      } else if (importTemplate === 'importOrg') {
        downLoadTemplate({ id: this.modelId })
      } else if (importTemplate === 'exportBatchSetTemplate') {
        // 下载批量定薪模板
        exportBatchSetTemplate({ type: matchField })
      } else if (importTemplate === 'exportBatchUpdateTemplate') {
        // 下载批量调薪模板
        exportBatchUpdateTemplate({ type: matchField })
      } else {
        this.importTemplate ? getImportTemplate({ templateCode: importTemplate }) : downLoadTemplate({ id: this.modelId })
      }
    },
    // 下载排班表
    downloadShift(data) {
      const { matchField, importTemplate } = this
      getImportTemplate({ templateCode: importTemplate, matchField, yearMonth: moment(data).format('YYYYMM') })
    },
    async customRequest() {
      const xlsx = this.fileInfo
      const formData = new FormData()
      formData.append('file', xlsx)
      const config = {
        loading: false,
        onUploadProgress: progressEvent => {
          const complete = (progressEvent.loaded / progressEvent.total) * 100
          this.schedule = Math.trunc(complete)
        }
      }
      this.uploadContentStatus = 'before'
      let res
      switch (this.importTemplate) {
        case 'clock': // 打卡
          res = await batchImportClock(this.matchField, formData)
          break
        case 'append_punch': // 补卡
          res = await batchImportApplyAppendPunch(this.matchField, formData)
          break
        case 'vacation': // 请假
          res = await batchImportApplyVacation(this.matchField, formData)
          break
        case 'outside': // 外出
          res = await batchImportApplyOutside(this.matchField, formData)
          break
        case 'travel': // 出差
          res = await batchImportApplyTravel(this.matchField, formData)
          break
        case 'overtime': // 加班
          res = await batchImportApplyOvertime(this.matchField, formData)
          break
        case 'shift': // 排班表
          res = await batchImportShift(this.matchField, formData)
          break
        case 'change_shift': // 调班
          res = await batchImportChangeApi(this.matchField, formData)
          break
        case 'exchange_shift': // 换班
          res = await batchImportExchangeApi(this.matchField, formData)
          break
        case 'vacation_adjustment_grant': // 批量发放假期
          res = await batchImportGrantVacationAdjustment(this.matchField, formData)
          break
        case 'vacation_adjustment_deduct': // 批量扣减假期
          res = await batchImportDeductVacationAdjustment(this.matchField, formData)
          break
        case 'importOrg': // 批量导入组织
          res = await checkBatchImportOrg(formData)
          break
        case 'exportBatchSetTemplate': // 人员批量定薪
          res = await batchSetFixedSalary(formData)
          break
        case 'exportBatchUpdateTemplate': // 人员批量调薪
          res = await updateStaffFixedSalary(formData)
          break
        default:
          res = await checkBatchImportStaff(formData, config)
      }
      if (res.code === 100) {
        this.uploadContentStatus = 'ok'
        // this.$message.info('加载文件完成！')
        if (res.data.errorList.length === 0) {
          if (res.data.dataList.length === 0) {
            this.dataList = res.data
            this.$message.info(this.close('无数据'), 3)
          } else {
            this.dataList = res.data
            this.$message.success(this.close('数据导入成功'), 3)
            this.uploadSuccess = true
          }
        } else {
          this.dataList = res.data
          this.$message.warning(this.close('文件中存在不符合规则的数据，请修改后重新导入校验'), 3)
          this.uploadSuccess = false
        }
        this.$refs.extable.initColumns(this.dataList ? this.dataList : '无数据')
        this.$refs.extable.initData(this.dataList ? this.dataList : '无数据')
      } else if (res.code === 400000) {
        this.uploadContentStatus = 'before'
        this.$message.error(this.close('导入失败'), 3)
        this.uploadSuccess = false
      } else {
        this.uploadContentStatus = 'before'
        this.uploadSuccess = false
      }
    },
    beforeUpload(file) {
      this.fileInfo = file
      const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      if (!isXlsx) {
        this.$message.error('文件类型错误！只支持xlsx文件！')
        return false
      }
      const isLtM = file.size / 1024 / 1024 < this.fileSize
      if (!isLtM) {
        this.$message.error(`文件大小不能超过${this.fileSize}M！`)
        return false
      }
      this.uploadContentStatus = 'ing'
      return isXlsx && isLtM
      // if (isXlsx && isLtM) {
      //   this.updateBtn = true
      // }
      // return false
    },
    handleChange(info) {
    },
    close(str) {
      return (<span ref='modelTitle' class='unit_span'>{str}</span>)
    },
    onClose() {
      this.$message.destroy()
    },
    showModel() {
      this.confirmUpload()
    },
    async confirmUpload() {
      const xlsx = this.fileInfo
      const formData = new FormData()
      formData.append('file', xlsx)
      let res
      switch (this.importTemplate) {
        case 'importOrg': // 打卡
          res = await batchImportOrg(formData)
          break
        default:
          res = await batchImportStaff(formData)
      }
      if (res.code === 100) {
        this.$message.success('数据导入成功')
        this.$emit('getStaffStatistics')
      }
    },
    errorClick() {
      this.$message.warning('请先处理表格中的异常数据')
    }
  }
}
</script>

<style lang="less" scoped>
.upload-file {
  // padding: 32px 20px 38px 20px;
  .top_model{
    background-color: @gray-4;
    border-radius: 4px;
    padding: 10px 20px;
    margin-bottom: 20px;
    p{
      margin: 0;
      padding: 0;
      padding-bottom: 5px;
      color: @gray-10;
      span{
        color: @gray-8;
        margin-left: 10px;
      }
    }
   }
   .container{
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
   }
   .upload_main{
      background-color: @gray-4;
      border-radius: 4px;
      padding: 10px 20px;
      .upload_main_p{
      margin: 0;
      margin-bottom: 5px;
      padding: 5px 0;
      color: @gray-10;
    }
      .ant-upload-text{
        margin-right: 5px;
        font-size: 14px;
      }
      .ant-upload-hint{
        margin-top:6px;
        color: @gray-7;
      }
      a{
          // text-decoration-line: underline;
          padding-bottom: 1px;
          border-bottom: 1px @sc-primary-100 solid;
        }
    .xlsx_img{
      width: 50px;
      height: 60px;
    }
   }
   .loading{
    display: flex;
    justify-content: center;
    align-items: center;
   }
}
 .close_icon{
    color: @gray-10;
    width: 10px;
    height: 10px
   }
/deep/ .ant-btn-sm{
  height: 30px;
}
/deep/ .ant-upload.ant-upload-drag{
  background-color: @sc-greyBg-10;
}
/deep/.ant-upload:hover{
      background: @sc-primary-10;
    }
    /deep/.ant-upload-list-item-thumbnail {
      top: 13px;
    }
    /deep/.ant-upload-list-item-card-actions .anticon{
      padding-top:6px;
    }
    /deep/.ant-upload-list-item-info{
      line-height:90px;
    }
    /deep/.ant-upload.ant-upload-drag{
      border:1px dashed @sc-primary-100;
    }
  .title {
    margin: 20px auto;
    width: 100%;
   }
   .icon_img{
    width: 16px;
    height: 16px;
    margin-right: 3px;
    vertical-align: top;
   }
   .confirm_btn{
    width: 100%;
    height: 50px;
    line-height: 36px;
    border-top: 2px @gray-5 solid;
    // position: absolute;
    // bottom: 0px;
    display: flex;
    justify-content: center;
    padding: 8px;
    background-color: #fff;
   }
   .error_btn{
    background-color: @sc-primary-40;
    border: none;
   }
</style>
